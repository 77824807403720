import React from 'react'
import { hydrateRoot, createRoot } from 'react-dom/client'
import './index.css'
import App from './App'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import About from './pages/About'
import Contact from './pages/Contact'
import Home from './pages/Home'

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/home',
        element: <Home />,
      },
      {
        path: 'about',
        element: <About />,
      },
      {
        path: 'contact',
        element: <Contact />,
      },
    ],
  },
])

root.render(<RouterProvider router={router} />)

if (rootElement.hasChildNodes()) {
  // createRoot(<App />, rootElement)
} else {
  // hydrateRoot(rootElement, <App />)
}
