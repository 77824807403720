function About() {
  return (
    <>
      <div>
        <h1>About</h1>
        <p>Welcome to our About page!</p>
      </div>
    </>
  )
}

export default About
