import React from 'react'
import { Link } from 'react-router-dom'
import { Outlet } from 'react-router-dom'

function App() {
  return (
    <>
      <Link to="/">Home</Link>
      <Link to="/about">About</Link>
      <Link to="/contact">Contact</Link>

      <Outlet />
    </>
  )
}

export default App
