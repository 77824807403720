function Contact() {
  return (
    <>
      <div>
        <h1>Contact</h1>
        <p>Welcome to our Contact page!</p>
      </div>
    </>
  )
}

export default Contact
